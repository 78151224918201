import type { FirebaseError } from 'firebase/app';
import type { CommentsProfileData } from '@/components/Pushly/types/pushly';

/* eslint-disable @typescript-eslint/naming-convention */
type RGIS = 'RGIS';
type USER_TOKEN = 'mut';
type CORAL_TOKEN = 'mct';

export type VerifyIdTokenResult = {
	emailHash?: string;
	emailHashes: Array<EmailHash>;
	tokens: Record<CORAL_TOKEN | RGIS | USER_TOKEN, null | string | undefined>;
};
export type EmailHash = {
	alg: string;
	digest: string;
};
export type KnownUser = {
	userProfile: {
		displayName?: null | string;
		email: null | string;
	};
};

export const sendEmailLink = async (
	email: string,
	targetUrl: string,
): Promise<void> => {
	const res = await fetch(`/.api/send-email-link`, {
		body: JSON.stringify({ email, targetUrl }),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
	});
	if (!res.ok) {
		throw new Error(`${(await res.json()).message}/${res.status}`);
	}
};

export const verifyIdToken = async (
	idToken: string,
): Promise<VerifyIdTokenResult> => {
	const res: Response = await fetch('/.api/verify-id-token', {
		body: JSON.stringify({ idToken }),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
	});
	const data = await res.json();
	if (!res.ok) {
		throw new Error(`${data.message}/${res.status}`);
	}

	return {
		emailHash: data?.userResponse?.emailHashes?.find(
			(item: EmailHash) => item.alg === 'sha256',
		)?.digest,
		emailHashes: data?.userResponse?.emailHashes,
		tokens: {
			mct: data?.tokens?.coralToken?.token,
			mut: data?.tokens?.userToken?.token,
			// https://github.com/themaven-net/web-chilliwack/blob/46ac5f2b9b2ab0570446bf46cb8208f7ab78f95f/src/util/UserLoginStore.ts#L50
			RGIS: JSON.stringify(data?.tokens?.rgis || ''),
		},
	};
};

export const getCommentsProfile = async (
	rgis: string,
	siteKeyword: string,
): Promise<CommentsProfileData> => {
	const rgisParam = rgis.replace(/^"/, '').replace(/"$/, '');
	const response = await fetch(
		`/.api/memberships/comments-profile?siteKeyword=${siteKeyword}&rgis=${encodeURIComponent(rgisParam)}`,
	);
	if (!response.ok) {
		const err = new Error(`${response.status}`);
		throw err;
	}
	return response.json();
};

export const getKnownUser = async (
	rgisCookieValue?: null | string,
): Promise<KnownUser> => {
	if (!rgisCookieValue) {
		return Promise.resolve({
			userProfile: {
				email: null,
			},
		});
	}
	return fetch('/.api/user/profile', {
		headers: {
			'Content-Type': 'application/json',
			// For some reason the returned cookie has quotes.
			'X-RGIS': rgisCookieValue.replace(/^"/, '').replace(/"$/, ''),
		},
		method: 'POST',
	}).then((resp) => {
		if (resp.status !== 200) {
			return {
				userProfile: {
					email: null,
				},
			};
		}
		return resp.json();
	});
};

export const updateUsername = (
	displayName: string,
	rgisCookieValue: string,
	siteId: string,
) => {
	return fetch('/.api/user/display-name', {
		body: JSON.stringify({ displayName, siteId }),
		headers: {
			'Content-Type': 'application/json',
			'X-RGIS': rgisCookieValue.replace(/^"/, '').replace(/"$/, ''),
		},
		method: 'PUT',
	}).then((resp) => {
		return resp.json();
	});
};

export const normalizeFirebaseError = (error: FirebaseError) => {
	if (
		error.code === 'auth/invalid-credential' &&
		error.message.includes('error=consent_required')
	) {
		return { code: 'auth/user-cancelled' };
	}
	if (error.message.includes('HTTP Cloud Function returned an error:')) {
		const firstIndex = error.message.indexOf('{');
		const lastIndex = error.message.lastIndexOf('}');
		const errorObject = JSON.parse(
			error.message.substring(firstIndex, lastIndex + 1),
		);
		const newErrorMessage =
			errorObject && errorObject.error && errorObject.error.message;
		return {
			code: error.code,
			message: newErrorMessage || error.message,
		};
	}
	return error;
};
