import { ONE_YEAR_COOKIE } from '@/constants';
import type { FirebaseError } from 'firebase/app';
import { getCookie, setCookie } from '@/utils/cookies';
import {
	getKnownUser,
	verifyIdToken,
	sendEmailLink,
	normalizeFirebaseError,
	type VerifyIdTokenResult,
} from '@/utils/sso';
import {
	useRef,
	useMemo,
	type FC,
	useState,
	useContext,
	useCallback,
	createContext,
	type MutableRefObject,
	type PropsWithChildren,
} from 'react';
import {
	signOut,
	type Auth,
	type User,
	OAuthProvider,
	signInWithPopup,
	getRedirectResult,
	GoogleAuthProvider,
	signInWithRedirect,
	type UserCredential,
	signInWithEmailLink,
	FacebookAuthProvider,
	isSignInWithEmailLink,
} from 'firebase/auth';

export enum OAuthProviderType {
	GOOGLE = 'google',
	FACEBOOK = 'facebook',
	MICROSOFT = 'microsoft',
	APPLE = 'apple',
}

export interface LoginContextType {
	afterCompleteLoginFnRef: MutableRefObject<
		(tokens: VerifyIdTokenResult, loginType: LoginType) => void
	>;
	emailLinkPhase: EmailLinkPhase | null;
	handleAfterFirebaseLogin: () => void;
	handleSendEmailLink: (email: string, loginUrl: string) => Promise<void>;
	isLoading: boolean;
	logOut: () => void;
	message: string;
	oauthPhase: null | OAuthPhase;
	rgisCookie: null | string;
	signInOAuth: (providerId: OAuthProviderType) => void;
}

export interface LoginProviderProps {
	auth: Auth;
}

export enum EmailLinkPhase {
	INPUT = 'input',
	SENDING_EMAIL = 'sending',
	SENT = 'sent',
	CONFIRM = 'confirm',
	SIGNING_IN = 'signing_in',
	COMPLETED = 'completed',
}

export enum OAuthPhase {
	SIGNING_IN = 'signing_in',
	CHOOSE = 'choose',
}

export enum LoginType {
	EMAIL_LINK = 'email_link',
	OAUTH = 'oauth',
}

const EMAIL_KEY = 'phoenix:login:email';
export const TARGET_URL_KEY = 'phoenix:login:targetUrl';

export const LoginContext = createContext<LoginContextType | null>(null);

export const LoginProvider: FC<PropsWithChildren<LoginProviderProps>> = ({
	auth,
	children,
}) => {
	const getRgisCookie = () => {
		const rgis = getCookie('RGIS', { decode: false });
		return rgis || null;
	};

	const [rgisCookie, setRgisCookie] = useState<null | string>(getRgisCookie);
	const [message, setMessage] = useState<string>('');
	const [emailLinkPhase, setEmailLinkPhase] = useState<EmailLinkPhase | null>(
		null,
	);
	const [oauthPhase, setOauthPhase] = useState<null | OAuthPhase>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const afterCompleteLoginFnRef = useRef<
		(tokens: VerifyIdTokenResult, loginType: LoginType) => void
	>(() => {});

	const completeLogin = useCallback(
		async (user: User, loginType: LoginType) => {
			const idToken = await user.getIdToken();

			// We may want to keep the Firebase user logged-in for something in the future, but
			// for now we don't need it once we get the token.
			await signOut(auth);

			const result = await verifyIdToken(idToken);

			// set email hashes in local storage
			if (result.emailHashes) {
				window.localStorage.setItem(
					'emailHashes',
					JSON.stringify(result.emailHashes),
				);
			}

			Object.entries(result.tokens).forEach(([tokenKey, tokenValue]) => {
				if (!tokenValue) return;
				setCookie(tokenKey, tokenValue, {
					encode: false,
					maxAge: ONE_YEAR_COOKIE,
					path: '/',
					secure: window.location.protocol !== 'http:',
				});
			});

			const knownUser = await getKnownUser(result.tokens.RGIS);
			const userRawEmail = knownUser.userProfile.email;

			if (userRawEmail && window.lockrAIM?.setAdditionalData) {
				window.lockrAIM.setAdditionalData({
					data: userRawEmail,
					type: 'email',
				});
			}

			if (result.emailHash && window.permutive) {
				await window.permutive.identify([
					{
						id: result.emailHash,
						tag: 'email_sha256',
					},
				]);
			}

			if (result.tokens.RGIS) {
				setRgisCookie(result.tokens.RGIS);
			}

			setMessage('Signed in. Redirecting...');

			setEmailLinkPhase(EmailLinkPhase.COMPLETED);
			setOauthPhase(null);
			afterCompleteLoginFnRef.current(result, loginType);
		},
		[auth],
	);

	const confirmEmail = useCallback(
		(emailAddress: string) => {
			setMessage('Signing in...');

			signInWithEmailLink(auth!, emailAddress, window.location.href)
				.then(async (userCredential) => {
					try {
						await completeLogin(userCredential.user!, LoginType.EMAIL_LINK);
					} catch (error) {
						setMessage(
							`An error occurred during the sign-in process. ${error as string}`,
						);
						setEmailLinkPhase(EmailLinkPhase.CONFIRM);
					}

					localStorage.removeItem(EMAIL_KEY);
				})
				.catch((error) => {
					if (error.code === 'auth/invalid-action-code') {
						setMessage(
							'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
						);
						setEmailLinkPhase(null);
					} else if (error.code === 'auth/invalid-email') {
						setMessage(
							'The email provided does not match the sign-in email address.',
						);
					} else {
						setMessage(
							`An error occurred during the sign-in process. ${error.message ?? error}`,
						);
					}
				});
		},
		[auth, completeLogin],
	);

	const continueEmailSignIn = useCallback(() => {
		if (!auth) return;

		// To prevent a sign-in link from being used to sign in as an unintended user or on an unintended
		// device, Firebase Auth requires the user's email address to be provided when completing the sign-in
		// flow. For sign-in to succeed, this email address must match the address to which the sign-in link
		// was originally sent.
		const emailFromStorage = localStorage.getItem(EMAIL_KEY);

		// If there is no email address in local storage, for example because the link is being used in a
		// different browser than the one that requested the link, the user is asked to enter it.
		if (!emailFromStorage) {
			setEmailLinkPhase(EmailLinkPhase.CONFIRM);
			return;
		}

		confirmEmail(emailFromStorage);
	}, [auth, confirmEmail]);

	const handleSignInSuccess = useCallback(
		async (result: UserCredential) => {
			setOauthPhase(OAuthPhase.SIGNING_IN);
			setMessage('Signing in...');

			await completeLogin(result.user!, LoginType.OAUTH).catch((error) => {
				setMessage(
					`An error occurred during the sign-in process. ${error as string}`,
				);
				setOauthPhase(OAuthPhase.CHOOSE);
			});
		},
		[completeLogin],
	);

	const handleSignInError = useCallback(
		(error: FirebaseError) => {
			const normalizedError = normalizeFirebaseError(error);

			let errorMessage = '';
			switch (normalizedError.code) {
				case 'auth/account-exists-with-different-credential':
					errorMessage =
						'An account already exists with the same email address but with a different sign-in method. Please sign in through the email field below.';
					window.reportErrorToPetametrics(
						`Login failed. ${message}`,
						normalizedError as Error,
					);
					break;
				case 'auth/internal-error':
					errorMessage = normalizedError.message;
					window.reportErrorToPetametrics(
						`Login failed. ${message}`,
						normalizedError as Error,
					);
					break;
				default: {
					errorMessage = `An error occurred during the sign-in process. ${normalizedError.message}`;
					break;
				}
			}

			setMessage(errorMessage);
		},
		[message],
	);

	const regularSignIn = useCallback(() => {
		if (!auth) return;

		setEmailLinkPhase(EmailLinkPhase.INPUT);

		// The getRedirectResult function below takes time to return results if the page load is the last step
		// of the redirect auth flow. So, we will display the loading screen before displaying the sign-in
		// options so that it does not make the user feel as if nothing is happening.
		setIsLoading(true);

		getRedirectResult(auth)
			.then(async (result): Promise<null | void> => {
				if (result) {
					return handleSignInSuccess(result);
				}
				localStorage.setItem(
					TARGET_URL_KEY,
					new URLSearchParams(window.location.search).get('targetUrl') ?? '',
				);
				return null;
			})
			.catch(handleSignInError)
			.finally(() => {
				setIsLoading(false);
			});
	}, [auth, handleSignInError, handleSignInSuccess]);

	const handleAfterFirebaseLogin = useCallback(() => {
		if (isSignInWithEmailLink(auth, window.location.href)) {
			setEmailLinkPhase(EmailLinkPhase.SIGNING_IN);
			continueEmailSignIn();
		} else {
			regularSignIn();
		}
	}, [auth, continueEmailSignIn, regularSignIn]);

	const handleSendEmailLink = useCallback(
		async (email: string, loginUrl: string) => {
			setEmailLinkPhase(EmailLinkPhase.SENDING_EMAIL);
			localStorage.setItem(EMAIL_KEY, email);
			try {
				await sendEmailLink(email, loginUrl);
				setEmailLinkPhase(EmailLinkPhase.SENT);
				setOauthPhase(null);
			} catch (error) {
				setMessage(`Failed to send email: ${error as string}`);
			}
		},
		[],
	);

	const signInOAuth = useCallback(async (providerId: OAuthProviderType) => {
		let provider;
		switch (providerId) {
			case OAuthProviderType.GOOGLE:
				provider = new GoogleAuthProvider();
				break;
			case OAuthProviderType.FACEBOOK:
				provider = new FacebookAuthProvider();
				break;
			case OAuthProviderType.APPLE:
				provider = new OAuthProvider('apple.com');
				break;
			case OAuthProviderType.MICROSOFT:
				provider = new OAuthProvider('microsoft.com');
				break;
			default:
				// eslint-disable-next-line no-console
				console.log(`Unknown providerId: ${providerId}`);
				return;
		}

		provider.addScope('email');

		if (process.env.NEXT_PUBLIC_SSO_FLOW !== 'redirect') {
			signInWithPopup(auth!, provider)
				.then(handleSignInSuccess)
				.catch(handleSignInError);
		} else {
			await signInWithRedirect(auth!, provider);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logOut = useCallback(() => {
		// remove RGIS cookie
		setCookie('RGIS', '', {
			expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
			path: '/',
		});
		setRgisCookie(null);
		setEmailLinkPhase(null);
		setOauthPhase(null);
		setIsLoading(false);
	}, []);

	const contextValue = useMemo(
		() => ({
			afterCompleteLoginFnRef,
			emailLinkPhase,
			handleAfterFirebaseLogin,
			handleSendEmailLink,
			isLoading,
			logOut,
			message,
			oauthPhase,
			rgisCookie,
			signInOAuth,
		}),
		[
			emailLinkPhase,
			handleSendEmailLink,
			handleAfterFirebaseLogin,
			message,
			isLoading,
			oauthPhase,
			signInOAuth,
			rgisCookie,
			logOut,
		],
	);

	return (
		<LoginContext.Provider value={contextValue}>
			{children}
		</LoginContext.Provider>
	);
};

export const useLoginContext = () => {
	const context = useContext(LoginContext);

	if (!context) {
		throw new Error('useLoginContext must be used within a LoginProvider');
	}

	return context;
};
