/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* global googletag */

/**
 * Provides an AdService class for managing ad slots, auctions, and ad requests.
 */

import type { FC } from 'react';
import { Zones } from '@/types/ads';
import { isClient } from '@/utils/is-client';
import type { AdSlotModel } from '@/types/ads';
import { cleanPath } from '@/utils/clean-path';
import type { Experiment } from '@/types/experiment';
import { isProduction } from '@/utils/is-production';
import { getPageOfDay } from '@/utils/get-page-of-day';
import type { ISiteContext } from '@/context/SiteContext';
import { getElementOffset } from '@/utils/get-element-offset';
import { getPostIabTermsId } from '@/utils/get-post-iab-terms';
import type { ClientSiteConfig } from '@/temporary-site-config';
import {
	type Event,
	USER_ARTICLE_VIEW,
	eventsFilterByType,
} from '@/utils/events';

import { DoubleVerifyBootstrap } from './double-verify';
import { zoneSizeMapping, zoneHeightMapping } from './zones';
import { GptBootstrap, sanitizeTargetingValue } from './gpt';
import { serializeExperiments } from '../serialize-experiments';
import { AuctionManager, AuctionBootstrap } from './auction-manager';

// Number of milliseconds to wait for additional slots to signal for an auction
// after the first slot signals.
const AUCTION_DEBOUNCE_MS = 200;

type SlotRenderEndedCallback = (
	e: googletag.events.SlotRenderEndedEvent,
) => void;
type DefineSlotOptions = {
	onRender?: SlotRenderEndedCallback;
};

type Timeout = ReturnType<typeof setTimeout>;
type TargetingKeyValues = Record<string, string | string[]>;

type GoogletagStub = {
	cmd: Array<(this: typeof globalThis) => void> | googletag.CommandArray;
};

type SlotRegistryEntry = {
	inViewPercentage?: number;
	model: AdSlotModel;
	onRender: null | SlotRenderEndedCallback;
	renderCount: number;
	slot: googletag.Slot | null;
};

type SlotRegistry = {
	[slotId: string]: SlotRegistryEntry | undefined;
};

type Tag = {
	name: string;
	taxonomy: string;
};
interface AdBootstrapProps {
	config: ClientSiteConfig;
}

export const AdBootstrap: FC<AdBootstrapProps> = ({ config }) => (
	<>
		<DoubleVerifyBootstrap config={config} />
		<AuctionBootstrap config={config} />
		<GptBootstrap />
	</>
);

export class AdService {
	private showAdsInternal: boolean = true;

	auctionManager: AuctionManager | null = null;

	auctionTimer: null | Timeout = null;

	// The continuousScrollCorrelator needs to be set when arrive on a page
	// with continuous scroll enabled.
	// It gets the value of the current page correlator i.e. AdService.viewCorrelator.
	// It should not be sent on any other type of page therefore the
	// continuousScrollCorrelator needs to be unset when we go to any other
	// type of page.
	continuousScrollCorrelator: number | undefined;

	siteContext: ISiteContext;

	slotRegistry: SlotRegistry = {};

	slotsToAuction: Array<string> = [];

	viewCorrelator: number | undefined;

	experiments: Experiment[];

	viewCorrelatorUrl: string | undefined;

	scrollIdx: null | number;

	constructor(siteContext: ISiteContext, ops: { experiments: Experiment[] }) {
		this.siteContext = siteContext;
		this.experiments = ops.experiments;
		this.scrollIdx = null;

		if (!isClient) {
			return;
		}

		eventsFilterByType(USER_ARTICLE_VIEW).subscribe(
			this.handleArticleView.bind(this),
		);

		if (typeof googletag === 'undefined') {
			const gtag: GoogletagStub | typeof googletag = { cmd: [] };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.googletag = gtag;
		}

		this.rotateCorrelator();
		this.auctionManager = new AuctionManager(this);
		googletag.cmd.push(() => this.initialize());
	}

	addEventListener(
		event: keyof googletag.events.EventTypeMap,
		handler: (
			arg: googletag.events.EventTypeMap[keyof googletag.events.EventTypeMap],
		) => void,
	) {
		if (!googletag.pubads) {
			googletag.cmd.push(() =>
				googletag.pubads().addEventListener(event, handler),
			);
		} else {
			googletag.pubads().addEventListener(event, handler);
		}
	}

	calculateMinHeight(model: AdSlotModel): null | number {
		const sizes =
			model.sizes || zoneSizeMapping[model.zone]?.[model.breakpoint] || [];
		const minHeight = sizes.reduce((min, size) => {
			// TBD: do we want to estimate a height for a fluid size?
			if (Array.isArray(size) && size[1] > 2) {
				return Math.min(min, size[1]);
			}
			return min;
		}, Number.MAX_SAFE_INTEGER);
		if (minHeight === Number.MAX_SAFE_INTEGER) {
			return null;
		}
		return minHeight;
	}

	getReservedHeight(model: AdSlotModel): null | number {
		return zoneHeightMapping[model.zone]?.[model.breakpoint] || null;
	}

	/**
	 * Define an ad slot to support the provided ad model.
	 * Model elements required: slotId, adUnitPath, sizes, breakpoint, index, zone.
	 * Also schedules a slot refresh (which queues an auction).
	 * @param {AdSlotModel} model - The model object containing the ad slot properties.
	 * @param {DefineSlotOptions} options - Optional parameters for defining the slot.
	 */
	defineSlot(model: AdSlotModel, options: DefineSlotOptions = {}) {
		if (!isClient) {
			return;
		}

		const { adUnitPath, breakpoint, sizes, slotId, zone } = model;
		const slotSizes = sizes || zoneSizeMapping[zone]?.[breakpoint];
		this.slotRegistry[slotId] = {
			model: { ...model, sizes: slotSizes },
			onRender: options.onRender ?? null,
			renderCount: 0,
			// replaced once async slot populates it
			slot: null,
		};

		const gptDefineSlot = () => {
			let slot;
			if (zone.includes(Zones.interstitial)) {
				slot = googletag.defineOutOfPageSlot(
					adUnitPath,
					zone === Zones.exit_interstitial
						? googletag.enums.OutOfPageFormat.INTERSTITIAL
						: slotId,
				);
				if (!slot) {
					console.error('!!! defineSlot fail', adUnitPath, zone);
					return;
				}
			} else {
				slot = googletag.defineSlot(adUnitPath, slotSizes, slotId);
				if (!slot) {
					this.destroySlot(slotId);
					slot = googletag.defineSlot(adUnitPath, slotSizes, slotId);
					if (!slot) {
						console.error('!!! defineSlot fail', adUnitPath, slotSizes, slotId);
						return;
					}
				}
			}
			slot.addService(googletag.pubads());
			if (this.slotRegistry[slotId]) {
				this.slotRegistry[slotId].slot = slot;
			}

			const pageTargets = this.pageTargetingFromModel(model);
			if (pageTargets) {
				this.setPageTargeting(pageTargets);
			}
		};

		if (!googletag.pubads) {
			googletag.cmd.push(() => gptDefineSlot());
		} else {
			gptDefineSlot();
		}
	}

	destroySlot(slotId: string) {
		if (!googletag.pubads) {
			googletag.cmd.push(() => this.destroySlot(slotId));
		} else if (this.slotRegistry[slotId]) {
			const { slot } = this.slotRegistry[slotId];
			if (slot) {
				const slotIndex = this.slotsToAuction.indexOf(slotId);
				if (slotIndex !== -1) {
					this.slotsToAuction.splice(slotIndex, 1);
				}
				googletag.destroySlots([slot]);

				// the slot is no more, but the model is still valid
				this.slotRegistry[slotId].slot = null;
			}
		}
	}

	disableAds() {
		// TODO: Logic to clean up slots, cease auction activity, etc
		this.showAdsInternal = false;
	}

	enableAds() {
		this.showAdsInternal = true;
	}

	getSlotModels(slotIds: string[]) {
		return slotIds
			.map((slotId) => this.slotRegistry[slotId]?.model)
			.filter((sm) => !!sm);
	}

	getSlotRenderCount(slotId: string): number {
		return this.slotRegistry[slotId]?.renderCount !== undefined
			? this.slotRegistry?.[slotId]?.renderCount
			: 0;
	}

	getSlots(slotIds: string[]) {
		return slotIds
			.map((slotId) => this.slotRegistry[slotId]?.slot)
			.filter((s) => !!s);
	}

	getUserId() {
		// We're utilizing Permutive for user ID values, with an arenaGroup prefix
		const permutiveId = window.localStorage?.getItem?.('permutive-id');
		return permutiveId ? `arenaGroup-${permutiveId}` : null;
	}

	handleArticleView(event: Event) {
		this.scrollIdx = event.value.scrollIdx;
		this.setPageTargeting({
			author: `tm-${event.value.meta.author_profile_id[0] ?? ''}`,
			direct: event.value.meta.monetization === 'direct_only' ? '1' : '0',
			iab3terms: getPostIabTermsId(event.value),
			pageOfDay: String(getPageOfDay(true)),
			pagetype: event.value.meta.page_type,
			path: cleanPath(event.value.link),
			pv: String(event.value.scrollIdx),
			// for testing purposes; guarantees ad delivery
			terms:
				process.env.NEXT_PUBLIC_HOUSE_ADS === 'true'
					? 'testads'
					: event.value.tags
							.filter((tag: Tag) => tag.taxonomy === 'post_tag' && !!tag.name)
							.map((tag: Tag) => tag.name),
		});
	}

	handleSlotRenderEndedEvent(event: googletag.events.SlotRenderEndedEvent) {
		const sre = this.slotRegistry[event.slot.getSlotElementId()];
		if (sre) {
			if (!event.isEmpty) {
				sre.renderCount = (sre.renderCount ?? 0) + 1;
			}
			sre.onRender?.(event);
		}
	}

	handleSlotVisibilityChangedEvent(
		event: googletag.events.SlotVisibilityChangedEvent,
	) {
		const { slot } = event;
		const sre = this.slotRegistry[slot.getSlotElementId()];
		if (!sre) {
			return;
		}

		sre.inViewPercentage = event.inViewPercentage;
		// TBD: trigger refresh on viewable threshold for slots that support it or haven't loaded yet
	}

	initialize() {
		// This mostly comes from htdocs/bootscripts/adServerDfp/adServerDfp.js (initGoogleAdManager)
		const pubAdsService = googletag.pubads();
		pubAdsService.enableSingleRequest();
		pubAdsService.disableInitialLoad();
		pubAdsService.setCentering(true);
		// if we have feature-flagged forcing safe frames, apply this
		// pubAdsService.setForceSafeFrame(true);
		pubAdsService.setSafeFrameConfig({
			allowOverlayExpansion: true,
			allowPushExpansion: true,
			sandbox: true,
		});

		const lazyLoadConfiguration = this.siteContext.config.ad.lazyLoading;
		if (!lazyLoadConfiguration.disabled) {
			pubAdsService.enableLazyLoad(
				lazyLoadConfiguration.thresholds || {
					fetchMarginPercent: 300,
					mobileScaling: 3,
					renderMarginPercent: 150,
				},
			);
		}

		// if we have a user ID, assign
		const userId = this.getUserId();
		if (userId) {
			pubAdsService.setPublisherProvidedId(userId);
		}

		googletag.enableServices();

		// event handling
		pubAdsService.addEventListener(
			'slotRenderEnded',
			this.handleSlotRenderEndedEvent.bind(this),
		);
		pubAdsService.addEventListener(
			'slotVisibilityChanged',
			this.handleSlotVisibilityChangedEvent.bind(this),
		);

		const pageTargets = {
			channel: 'web',
			correlator: String(this.viewCorrelator),
			cv: 'lifestyle',
			env: isProduction() ? 'prod' : 'qa',
			experiments: serializeExperiments(this.experiments),
			lang: 'en',
			loggedin: '0',
			trending: '0',
			// for testing purposes; guarantees ad delivery
			...(process.env.NEXT_PUBLIC_HOUSE_ADS === 'true'
				? { terms: 'testads' }
				: {}),
		};
		this.setPageTargeting(pageTargets);
	}

	lockContinuousScrollCorrelator() {
		this.continuousScrollCorrelator = this.viewCorrelator;
		this.setPageTargeting({
			initial_correlator: String(this.continuousScrollCorrelator),
		});
	}

	newCorrelator() {
		// Return a random integer; this will be used as an
		// identifier for all auctions on the page.
		function generateCorrelator() {
			// Use the Math.random function since
			// we do not need a cryptographically secure
			// number here
			const rand = Math.random();
			return Math.floor(rand * 2 ** 53);
		}
		const correlator1 = generateCorrelator();
		const correlator2 = generateCorrelator();

		let correlator: number | undefined;

		if (correlator1 === correlator2) {
			// Collision suggests that the browser is using a simulated
			// PRNG and thus is not a real user.
			correlator = 0;
		} else {
			correlator = correlator1;
		}
		return correlator;
	}

	pageTargetingFromModel(model: AdSlotModel): TargetingKeyValues {
		const { breakpoint } = model;
		return {
			breakpoint: `${breakpoint}`,
		};
	}

	queueAuction() {
		if (this.auctionTimer) {
			clearTimeout(this.auctionTimer);
			this.auctionTimer = null;
		}
		this.auctionTimer = setTimeout(
			() => this.runAuction(),
			AUCTION_DEBOUNCE_MS,
		);
	}

	refreshSlot(slotId: string) {
		const gptRefresh = () => {
			const { model, slot } = this.slotRegistry[slotId] ?? {};
			if (!slot) {
				return;
			}

			const targeting = model ? this.slotTargetingFromModel(model) : null;
			if (targeting) {
				Object.keys(targeting).forEach((key) =>
					slot.setTargeting(key, sanitizeTargetingValue(targeting[key])),
				);
			}

			if (document.getElementById(slotId)) {
				const display = () => googletag.display(slot);
				if (window.onDvtagReady) {
					window.onDvtagReady(display);
				} else {
					display();
				}
			}
		};

		if (!googletag.pubads) {
			googletag.cmd.push(() => gptRefresh());
		} else {
			gptRefresh();
		}

		this.slotsToAuction.push(slotId);
		this.queueAuction();
	}

	rotateCorrelator() {
		// Rotate the correlator. However, if we have a correlator and it
		// was created for the same URL presently viewed, do not rotate.
		if (
			!this.viewCorrelator ||
			this.viewCorrelatorUrl !== window.location.href
		) {
			this.viewCorrelator = this.newCorrelator();
			this.viewCorrelatorUrl = window.location.href;
			this.setPageTargeting({ correlator: String(this.viewCorrelator) });
		}
	}

	runAuction() {
		if (!this.auctionManager) {
			return;
		}
		// Note: we do not need to place this behind the googletag.cmd queue since
		// it won't require GPT to be loaded until the ad request is issued (which occurs
		// after GPT targeting is applied and the auction result is returned).
		const slotsToAuction = [
			...new Set(this.slotsToAuction.filter((slotId) => !!slotId)),
		];
		this.slotsToAuction = [];
		this.auctionManager.runAuction(slotsToAuction).then(() => {
			const slots = this.getSlots(slotsToAuction);
			const refresh = () => googletag.pubads().refresh(slots);
			googletag.cmd.push(() => {
				if (window.onDvtagReady) {
					window.onDvtagReady(refresh);
				} else {
					refresh();
				}
			});
		});
	}

	setPageTargeting(targeting: TargetingKeyValues) {
		if (!isClient) {
			return;
		}
		if (!googletag.pubads) {
			googletag.cmd.push(() => this.setPageTargeting(targeting));
		} else {
			const pubAdsService = googletag.pubads();
			Object.keys(targeting).forEach((key: string) =>
				pubAdsService.setTargeting(key, sanitizeTargetingValue(targeting[key])),
			);
		}
	}

	slotTargetingFromModel(model: AdSlotModel): TargetingKeyValues {
		const domain = this.siteContext.config.siteProductionDomain;
		const { index, slotId, zone: adzone } = model;
		const sre = this.slotRegistry[slotId];
		const renderCount = sre?.renderCount ?? 0;
		const adIndex = index + renderCount;
		const slotElement = sre?.slot?.getSlotElementId();
		let offset: null | number = null;
		if (slotElement) {
			offset = getElementOffset(document.getElementById(slotElement)) ?? null;
		}

		return {
			adindex: `${adIndex}`,
			adzone,
			adzoneindex: `${adzone}_${adIndex}`,
			cm: 'raven',
			deal: '0',
			// initial value for prebid dollar value; this gets set to
			// the actual value once the winning bid is known.
			hb_pbd: '0',
			siteadzoneindex: `${domain}_${adzone}_${adIndex}`,
			...(offset && {
				ypos: String(Math.min(Math.round(offset / 10) * 10, 100000)),
			}),
		};
	}

	unsetContinuousScrollCorrelator() {
		this.continuousScrollCorrelator = undefined;
		this.setPageTargeting({
			initial_correlator: '',
		});
	}

	get showAds(): boolean {
		return this.showAdsInternal;
	}
}
